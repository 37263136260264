import { Box, Button, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snack from './Snack';
import { api, createClasses } from './utils';
import { useUI } from './Context';

const classes = createClasses({
	root: {
		display: 'grid',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		gridTemplateColumns: "minmax(0, 500px)",
		minHeight: "100vh",
	},
	login: {
		border: '3px solid black',
		padding: 4,
		height: 'auto',
		borderRadius: '20px',
		display: 'grid',
		gridTemplateRows: 'auto',
		rowGap: 3.5,
		justifyItems: 'center',
	},
	text: {
		width: "75%",
		'& > label, & > div': {
			fontSize: '1.6rem!important',
		},
		'& p': {
			color: '#000',
			fontSize: '1rem',
		}
	},
	button: {
		fontSize: '1.7rem',
		fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif",
		width: "60%",
		paddingTop: 2,
		paddingBottom: 2,
		backgroundColor: "#000",
		'&:hover': {
			backgroundColor: "#3D72A4"
		}
	},
	snack: {
		fontSize: '1.5rem',
	}
})

interface CustomState {
	from: {
		pathname: string
	}
}

interface LoginProps {
}

const Login: React.FC<LoginProps> = () => {
	const { auth, setAuth, setLoader } = useUI()
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [snackOpen, setSnackOpen] = useState<Snack>({
		severity: undefined,
		message: '',
		open: false
	});
	const navigate = useNavigate();
	useEffect(() => {
		if (auth) navigate('/')
	}, [auth, navigate])
	const location = useLocation();
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (email && password) {
			try {
				let { res } = await api({
					path: '/login',
					variables: {
						email: email,
						password: password
					}
				});
				if (!res.ok || res.status !== 200) {
					throw {
						response: {
							status: res.status
						}
					}
				}
				setAuth(true)
				let { from } = (location.state as CustomState) || { from: { pathname: '/' } }
				navigate(from.pathname, { replace: true })
			} catch (err: any) {
				if (err && err.response && err.response.status) {
					let { status } = err.response;
					if (status > 400 && status < 500) {
						setSnackOpen({
							severity: 'error',
							message: "Incorrect Email or Password",
							open: true
						})
					} else if (status >= 500) {
						setSnackOpen({
							severity: 'error',
							message: "An error occured while logging in. Please try again later.",
							open: true
						})
						console.log(err);
					}
				} else {
					console.log(err);
				}
				setLoader(false);
			}
		} else {
			setSnackOpen({
				severity: "error",
				message: "Please enter email and password",
				open: true
			})
		}
	}
	const handleSnackClose = (_?: any, reason?: string) => {
		if (reason !== undefined && reason === 'clickaway') return;
		setSnackOpen(prev => ({
			...prev,
			open: false
		}));
	};
	return (
		<Box sx={classes.root}>
			<Box component="form" method="POST" onSubmit={handleSubmit} sx={classes.login}>
				<Typography variant="h4" color="textPrimary" style={{ textAlign: 'center' }}>Login</Typography>
				<TextField
					sx={classes.text}
					value={email}
					name="email"
					onChange={(event) => setEmail(event.currentTarget.value)}
					label="Email"
					variant="outlined"
				/>
				<TextField
					sx={classes.text}
					value={password}
					onChange={(event) => setPassword(event.currentTarget.value)}
					label="Password"
					variant="outlined"
					type={showPassword ? "text" : "password"}
					name="password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword(prev => !prev)}
										onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</Tooltip>
							</InputAdornment>
						)
					}}
				/>
				<Button sx={classes.button} type="submit" color="primary" variant="contained">Submit</Button>
			</Box>
			<Snack handleClose={handleSnackClose} snack={snackOpen} />
		</Box>
	)
}

export default Login
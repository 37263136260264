import { Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';

export default function Snack({
	snack,
	handleClose,
}: {
	snack: Snack,
	handleClose: (event: React.SyntheticEvent<any> | Event, reason?: string) => void,
}) {
	return (
		<Snackbar open={snack.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
			vertical: snack.vertical ? snack.vertical : 'bottom',
			horizontal: snack.horizontal ? snack.horizontal : 'center',
		}} >
			<Alert elevation={6} variant="filled" onClose={handleClose} severity={snack.severity}>
				{snack.message}
			</Alert>
		</Snackbar>
	)
}
import { Paper, TableContainer, Table as MuiTable, TableHead, TableRow, TableCell, Box, TableBody, Chip, Typography } from "@mui/material"
import { useUI } from "./Context"
import { Suspense, useEffect, lazy, useState, useCallback } from 'react'
import { api } from "./utils"
import { format } from "date-fns"

const CreateEvent = lazy(() => import("./CreateEvent"))

const useEvents = () => {
	const [data, setData] = useState<BannerEvent[]>([])
	const [error, setError] = useState<any>()
	const { setLoader } = useUI()

	const refetch = useCallback(async (signal?: AbortSignal) => {
		try {
			setLoader(true)
			const resp = await api<BannerEvent[]>({
				path: "/events",
				fetchOptions: {
					method: "GET",
					signal,
				}
			})
			if (resp.res.ok && resp.res.status === 200 && resp.data) {
				setData(resp.data)
				return
			}
		} catch (err: any) {
			if (err?.name !== "CanceledError" && err?.name !== "AbortError")
				setError(err)
		} finally {
			setLoader(false)
		}
	}, [setLoader])

	useEffect(() => {
		const controller = new AbortController()
		refetch(controller.signal)
		return () => {
			controller.abort()
		}
	}, [refetch])

	return { data, error, refetch }
}

interface TableProps {

}

const getChip = (startDate: Date, endDate?: Date) => {
	let today = new Date()
	if (today > startDate && ((endDate && endDate > today) || !endDate)) {
		return (<Chip color="success" label="Active" />)
	} else if (endDate && today > endDate) {
		return (<Chip color='error' label="Expired" />)
	} else {
		return (<Chip color="info" label="Scheduled" />)
	}
}

const Table: React.FC<TableProps> = () => {
	const { setLoader } = useUI()
	const { data, error, refetch } = useEvents()
	const [selected, setSelected] = useState<BannerEvent | null>(null)

	useEffect(() => {
		setLoader(false)
	}, [])

	const emptyRows = 5 - data.length

	const handleSelect = (item: BannerEvent) => {
		setSelected(item)
	}

	const handleClose = () => {
		setSelected(null)
	}


	return (
		<Box sx={{
			maxWidth: 1200,
			margin: "0 auto",
			px: "1.5rem",
			py: "5rem",
			position: "relative",
		}}>
			<Suspense fallback={<span></span>}>
				<CreateEvent refetch={refetch} current={selected} onClose={handleClose} />
			</Suspense>
			<TableContainer component={Paper}>
				<MuiTable>
					<TableHead>
						<TableRow>
							<TableCell>
								Event
							</TableCell>
							<TableCell>
								Status
							</TableCell>
							<TableCell>
								Start
							</TableCell>
							<TableCell>
								End
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map(item => {
							const startDate = new Date(item.startDate)
							const endDate = (item.endDate && new Date(item.endDate)) || undefined
							return (
								<TableRow key={item.id} hover sx={{
									cursor: "pointer"
								}} onClick={() => handleSelect(item)}>
									<TableCell>
										{item.name}
									</TableCell>
									<TableCell>
										{getChip(startDate, endDate)}
									</TableCell>
									<TableCell>
										<Typography>{format(startDate, "iii, MMM dd")}</Typography>
										<Typography variant="subtitle2" fontWeight="normal" color={"text.disabled"}>{format(startDate, "hh:mmaaa zzz")}</Typography>
									</TableCell>
									{endDate ? <TableCell>
										<Typography>{format(endDate, "iii, MMM dd")}</Typography>
										<Typography variant="subtitle2" fontWeight="normal" color={"text.disabled"}>{format(endDate, "hh:mmaaa zzz")}</Typography>
									</TableCell> : <TableCell>
										<Typography variant="subtitle2" fontWeight="normal" color={"text.disabled"}>Unsepcified</Typography>
									</TableCell>}
								</TableRow>
							)
						})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={8} />
							</TableRow>
						)}
					</TableBody>
				</MuiTable>
			</TableContainer>
		</Box>
	)
}

export default Table
const fetchFront = async <D,>({
	fetchOptions,
	path,
	variables,
}: {
	path: string,
	variables?: any,
	fetchOptions?: RequestInit,
	data?: boolean,
}): Promise<{
	data: D,
	res: Response,
}> => {
	try {
		let res = await fetch(`/api${path}`, {
			method: "POST",
			...fetchOptions,
			headers: {
				...fetchOptions?.headers,
				'Content-Type': 'application/json'
			},
			body: variables ? JSON.stringify(variables) : undefined,
		})
		const data = await res.json()

		return { data, res }
	} catch (err) {
		throw err
	}
}

export type LocalFetch = typeof fetchFront

export default fetchFront
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderProps {
	open: boolean
}

const Loader: React.FC<LoaderProps> = ({open}) => {
	return (
		<Backdrop id="loader_div" sx={{
			zIndex: 2000,
			color: '#000',
		}} open={open}>
			<CircularProgress size={70} color="inherit" />
		</Backdrop>
	);
}

export default Loader
import { api } from "./utils"
import { useEffect } from "react"
import Loader from "./Loader"
import { useLocation, Navigate, Routes, Route } from "react-router-dom"
import Login from './Login';
import { useUI } from "./Context";
import Table from "./Table";
import Snack from "./Snack";


const App = () => {
	const { auth, loader, snack, setSnack, setAuth } = useUI()
	useEffect(() => {
		const abort = new AbortController()
		api({
			path: "/auth",
			fetchOptions: {
				signal: abort.signal,
			}
		}).then(({ res }) => {
			if (res.ok && res.status === 200) {
				setAuth(true)
				return
			}
			setAuth(false)
		}).catch(err => {
			if (err?.name !== "CanceledError" && err?.name !== "AbortError") {
				console.log(err);
				setAuth(false)
			}
		})
		return () => {
			abort.abort()
		}
	}, [setAuth])

	const handleSnackClose = (_?: any, reason?: string) => {
		if (reason === 'clickaway') return;
		setSnack({
			...snack,
			open: false
		});
	};

	if (auth === null) {
		return (
			<Loader open />
		)
	}

	return (
		<main id="mainContent">
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<ProtectedPath auth={auth}><Table /></ProtectedPath>} />
			</Routes>
			<Loader open={loader} />
			<Snack handleClose={handleSnackClose} snack={snack} />
		</main>
	)
}

interface PrivatePath {
	auth: boolean,
	children: React.ReactNode,
}
const ProtectedPath: React.FC<PrivatePath> = ({ children, auth }) => {
	const location = useLocation()
	if (!auth) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}

	return <>
		{children}
	</>
}

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}


export default App

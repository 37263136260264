import React, { FC, useCallback, useMemo } from "react"

export interface State {
	auth: boolean | null,
	loader: boolean,
	snack: Snack,
}

const initialtState: State = {
	auth: null,
	loader: false,
	snack: {
		message: "",
		open: false,
		severity: "error",
	},
}

type Action =
	| {
		type: "AUTH"
		value: boolean,
	}
	| {
		type: "LOADER"
		value: boolean,
	}
	| {
		type: "SNACK"
		value: Snack,
	}

export const UIContext = React.createContext<State | any>(initialtState)

UIContext.displayName = "UIContext"

function uiReducer(state: State, action: Action) {
	switch (action.type) {
		case "AUTH":
			return {
				...state,
				auth: action.value,
			}
		case "LOADER":
			return {
				...state,
				loader: action.value,
			}
		case "SNACK":
			return {
				...state,
				snack: action.value,
			}
		default:
			return state;
	}
}

interface UIState {
	setAuth: (value: boolean) => void;
	setLoader: (value: boolean) => void;
	setSnack: (value: Snack) => void;
	auth: boolean | null;
	loader: boolean;
	snack: Snack;
}

export const UIProvider: FC<OldFC> = (props) => {
	const [state, dispatch] = React.useReducer(uiReducer, initialtState)

	const setAuth = useCallback(
		(value: boolean) => dispatch({ type: "AUTH", value }),
		[dispatch]
	)

	const setLoader = useCallback(
		(value: boolean) => dispatch({ type: "LOADER", value }),
		[dispatch]
	)

	const setSnack = useCallback(
		(value: Snack) => dispatch({ type: "SNACK", value }),
		[dispatch]
	)

	const value = useMemo(() => ({
		...state,
		setAuth,
		setLoader,
		setSnack,
	}), [state, setAuth, setLoader, setSnack])

	return <UIContext.Provider value={value} {...props} />

	// return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
	const context = React.useContext<UIState>(UIContext)
	if (context === undefined) {
		throw new Error(`useUI must be used within a UIProvider`)
	}
	return context
}
import React from 'react'
import ReactDOM from 'react-dom/client'
import App, { ScrollToTop } from './App'
import './index.css'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
	createTheme,
	useMediaQuery,
	ThemeProvider,
	StyledEngineProvider,
} from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { UIProvider } from './Context';

const ThemeRoot: React.FC<OldFC> = ({ children }) => {
	const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const theme = React.useMemo(() => createTheme({
		palette: {
			mode: darkMode ? 'dark' : "light",
			secondary: {
				light: '#ff7961',
				main: '#f44336',
				dark: '#ba000d',
				contrastText: '#000',
			},
			primary: {
				light: '#757ce8',
				main: '#3f50b5',
				dark: '#002884',
				contrastText: '#fff',
			},
			...(darkMode ? {
				text: {
					primary: "#fff",
					secondary: "rgba(255, 255, 255, 0.7)",
					disabled: "rgba(255, 255, 255, 0.5)"
				},
				background: {
					default: "#303030",
					paper: "#424242",
				},
				action: {
					active: "#fff",
					hover: "rgba(255, 255, 255, 0.08)",
					selected: "rgba(255, 255, 255, 0.16)",
					disabled: "rgba(255, 255, 255, 0.3)",
					disabledBackground: "rgba(255, 255, 255, 0.12)"
				},
				divider: "rgba(255, 255, 255, 0.12)",
			} : {})
		},
		typography: {
			fontFamily: "-apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif",
			htmlFontSize: 10,
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: "capitalize"
					}
				}
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						'&.Mui-focused': {
							color: "#9caaff",
						},
						'&.Mui-error': {
							color: "#f44336",
						},
					}
				}
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						'&.Mui-checked': {
							color: darkMode ? "#757ce8" : undefined,
						},
						'&.Mui-disabled': {
							color: darkMode ? "#ffffff4d" : undefined,
						},
					}
				}
			},
		}
	}), [darkMode])

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<UIProvider>
			<BrowserRouter>
				<ThemeRoot>
					<ScrollToTop />
					<App />
				</ThemeRoot>
			</BrowserRouter>
		</UIProvider>
	</React.StrictMode>
)
